<template>
  <b-container class="main-login">
    <!-- <b-row align-h="center">
      <b-col cols="8">
        <b-card text-variant="white" class="text-center">
          <img
            src="https://www.alianzadiagnostica.com/images/logo-img.png"
            alt=""
            class="mb-3"
          />
          <h4 style="background-color: #0A2799">
            Bienvenido al Servicio Virtual de Asignación de Citas
          </h4>
        </b-card>
      </b-col>
    </b-row> -->
    <b-row align-h="center" class="text-center">
      <b-col cols="12" sm="12" md="12" lg="8" xl="8">
        <b-card header-bg-variant="Info" class="text-left">
          <b-card-text class="text-center">
            <b-row>
              <b-col>
                <b-img 
                  src="https://saludtotal.com.co/wp-content/themes/salud-total/assets/img/logo-salud-total.svg" 
                  alt="Salud Total"
                  height="50%"
                ></b-img>
                <b-icon class="ml-3 mr-3" icon="dash" scale="4" rotate="-75" style="color:#0A2799"></b-icon>
                <b-img
                  src="https://www.alianzadiagnostica.com/images/logo-img.png"
                  alt=""
                  class="mb-3"
                  height="50%"
                ></b-img>
              </b-col>
            </b-row>
            <h4 style="color: #0A2799">
              Servicio Virtual de Asignación de Citas
            </h4>
            <h5 style="color: #0A2799">
              Protegidos Salud Total
            </h5>
          </b-card-text>
          <b-form v-show="authStep == 1 || authStep == 2">
            <!-- <b-form-group label="Tipo de Usuario:" label-for="input-1">
              <b-form-select
                id="input-1"
                v-model="form.userType"
                :options="userTypes"
                :value="null"
                required
              ></b-form-select>
            </b-form-group> -->
            <b-form-group
              label="Tipo de Identificación del Usuario:"
              label-for="input-2"
            >
              <b-form-select
                id="input-2"
                v-model="form.identificacionId"
                :options="identificationTypes"
                :value="null"
                required
              ></b-form-select>
            </b-form-group>
            <b-form-group
              label="Número de Identificación del Usuario:"
              label-for="input-3"
            >
              <b-form-input
                id="input-3"
                v-model="form.identificacion"
                pattern="[0-9]+"
                required
              ></b-form-input>
            </b-form-group>
            <div id="captcha" style="text-align: -webkit-center"></div>
            <b-form-group label-for="input-3">
              <b-alert :show="authStep == 2" fade variant="warning">
                Se enviará un correo electrónico a <b>{{formatEmailAfil}}</b> con un código de verificación de 6 dígitos. Revisar la bandeja de correo no deseado si es necesario.<br>
                <center>
                  <!-- <b-button style="background-color: #0A2799" size="sm" @click="onSubmit()">Si, enviar</b-button> -->
                <b-button variant="success" size="sm" @click="changeEmail = true; emailAfil=''">No es mi correo</b-button></center>
              </b-alert>
              <div v-show="changeEmail">
                <label for=""><b>Nuevo Correo Electrónico</b></label>
                <b-form-input cols="4" id="input-3" v-model="emailAfil" :required="authStep == 2 && changeEmail"></b-form-input>                
              </div>                            
            </b-form-group>
            <b-alert
              :show="dismissCountDown"
              dismissible
              fade
              variant="danger"
              @dismiss-count-down="countDownChanged"
            >
              {{ msgNotificationUno }}
            </b-alert>
            <!-- <b-form-group label="Clave:" label-for="input-4">
              <b-form-input
                id="input-4"
                type="password"
                v-model="form.password"
                required
              ></b-form-input>
            </b-form-group> -->
            <b-form-group class="mt-4 text-center">
              <b-button
                style="background-color: #0A2799"
                size="lg"
                @click="onSubmit()"
                >{{authStep == 2 ? 'Enviar Código' : 'Ingresar'}}</b-button
              >
            </b-form-group>
            <b-form-group class="mt-2 text-center">
              <b-link
                href="/pdf/manual_usuario_citas_web.pdf"
                target="blank"
                style="font-size: 1.5rem"
                >Manual para asignación de citas</b-link
              >
            </b-form-group>
            <b-row align-h="center">
              <div class="d-flex justify-content-center ">
                <b-icon
                  v-if="loading"
                  icon="arrow-repeat"
                  animation="spin-reverse"
                  font-scale="4"
                ></b-icon>
              </div>
            </b-row>
          </b-form>
          
          <b-form v-show="authStep == 3" class="mt-5">
            <b-form-group label-for="input-3">
              <p class="text-center"><b>Escribe el código que te enviamos al correo:</b></p>
              <b-row align-h="center">
                <b-col cols="4">                  
                  <b-form-input style="font-size: 1.5rem; font-weight: bolder; text-align: center;" size="lg" id="input-3" v-model="form.authCode" :required="authStep == 3"></b-form-input>
                </b-col>
              </b-row>
            </b-form-group>
            <b-alert
              :show="dismissCountDown"
              dismissible
              fade
              variant="danger"
              @dismiss-count-down="countDownChanged"
            >
              {{ msgNotificationDos }}
            </b-alert>
            <b-form-group class="mt-4 text-center">
              <b-button
                style="background-color: #0A2799"
                size="lg"
                @click="onSubmit()"
                >Ingresar</b-button
              >
            </b-form-group>
          </b-form>          
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
  import { mapState, mapActions } from "vuex";

  export default {
    data() {
      return {
        // form: {
        //   userType: "AFI",
        //   identificationType: "C",
        //   identification: "1085095941",
        //   password: "saludtotal",
        // },
        form: {
          userType: "AFI",
          identificacionId: null,
          identificacion: null,
          authCode: null
          // password: null,
        },
        msgNotificationUno: null,
        msgNotificationDos: null,
        showNotification: false,
        dismissSecs: 4,
        dismissCountDown: 0,
        showDismissibleAlert: false,
        loading: false,
        authStep: 1,
        emailAfil: '',
        formatEmailAfil: '',
        changeEmail: false
      };
    },
    computed: {
      ...mapState([
        "tokenAuth",
        "identificationTypes",
        "userTypes",
        "tokenAfiliado",
      ]),
    },
    mounted() {      
      setTimeout(() => {
        grecaptcha.render('captcha', {
          'sitekey' : '6LesdFkgAAAAAOvYwr5gsWEyKHO9xl0Z0S7_4lqL'
        });
      }, 500);
    },
    methods: {
      ...mapActions(["loginApi", "logout", "getGrupoFamiliarAfil"]),
      firstStepLogin(formData){
        this.loginApi(formData)
          .then(async (response) => {
            this.$store.dispatch('firstStepLogin',formData)
              .then(rta => {
                this.loading = false;
                if(rta.sinAsignar){
                  this.msgNotificationUno =
                    "El afiliado no se encuentra en la población asignada a Alianza Diagnóstica";
                  this.showNotification = true;
                  this.dismissCountDown = 8;                  
                  return
                }
                if (rta.length == 0) {
                  this.msgNotificationUno = "El documento de identificación ingresado no se ha encontrado en la base de datos de protegidos de Salud Total. Por favor verifique la información.";
                  this.showNotification = true;
                  this.dismissCountDown = 8;
                  return
                }
                if(rta.invalidEmail){
                  this.changeEmail = true;
                  this.emailAfil =  ''
                  this.formatEmailAfil = ''
                }else{
                  this.emailAfil =  rta.email                  
                  this.formatEmailAfil = this.formatHideEmail(rta.email)                                    
                  this.$store.commit('setAfiliadoSaludTotal',rta.esSaludTotal);
                  if(!rta.esSaludTotal){
                    this.$store.commit('setNombreAfiliadoAdx',rta.nombreAfiliadoAdx);
                  }
                }
                this.authStep = 2;  
              })
          })
          .catch((error) => {
            this.loading = false;
            this.msgNotificationUno = "Datos de acceso incorrectos.";
            this.showNotification = true;
            this.dismissCountDown = this.dismissSecs;
          });
      },
      secondStepLogin(){
        let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if(!regex.test(this.emailAfil)){
          this.msgNotificationUno = "La dirección de correo electrónico ingresada no es válida.";
          this.showNotification = true;
          this.dismissCountDown = 8;
          this.loading = false;
          return;
        }
        this.loading = true;
        this.$store.dispatch('sendEmailVerifycation',{
            email: this.emailAfil,
            isNewEmail: this.changeEmail,
            identificacion: this.form.identificacion,
            identificacionId: this.form.identificacionId 
          }).then(rta => {
          this.loading = false;
          if(rta.nextAuthStep){
            this.authStep = 3;
          }
        })
      },
      thirdStepLogin(){
        this.loading = true;
        this.$store.dispatch('validateCodeEmail',{
          authCode: this.form.authCode,
          identificacion: this.form.identificacion,
          identificacionId: this.form.identificacionId,
          newEmail: this.changeEmail ? this.emailAfil : null
        }).then(rta => {
          this.loading = false;
          if(rta.invalidCode){
            this.msgNotificationDos =
              "El código de autenticación no corresponde.";
            this.showNotification = true;
            this.dismissCountDown = 8;
            return
          }
          this.showNotification = false;
          this.msgNotificationUno = null;
          this.msgNotificationDos = null;
          this.getGrupoFamiliarAfil(this.form).then(rta => {
            this.$router.push("/");
          })
        })
      },
      onSubmit(e) {
        //Validamos la captcha en los pasos 1 y 2
        if(this.authStep < 3){
          let verifyCaptcha = grecaptcha.getResponse();
          if(!verifyCaptcha){
            this.msgNotificationUno =
              "Debes completar la validación";
            this.showNotification = true;
            this.dismissCountDown = 6;
            return
          }
        }        
        this.loading = true;
        let tmpId = this.identificationTypes.find(
          (item) => item.value == this.form.identificacionId
        );
        let formData = {
          identificacion: this.form.identificacion.trim(),
          identificacionId: tmpId.id
        };
        switch (this.authStep) {
          case 1:
            this.firstStepLogin(formData);
            break;
          case 2:
            this.secondStepLogin();
            break;
          case 3:
            this.thirdStepLogin();
          default:
            break;
        }
      },
      countDownChanged(dismissCountDown) {
        this.dismissCountDown = dismissCountDown;
      },
      showAlert() {
        this.dismissCountDown = this.dismissSecs;
      },
      formatHideEmail(correo){
        let dominio = correo.substr(correo.indexOf('@')); 
        let cuenta = correo.substr(0,correo.indexOf('@'))
        let mostrar = cuenta.substr(0,cuenta.length-(cuenta.length/2))                
        return mostrar+'*****'+dominio;
      }
    },
  };
</script>
<style>
  .main-login {
    margin-top: 5vh;
  }
</style>
